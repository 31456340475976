import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Pedidos } from '../service/Pedidos';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { Redirect } from 'react-router-dom';

export const VerPedidosComponentes = () => {
    
    const UrlGral = localStorage.getItem("UrlGral")

    const [customer2, setCustomer2] = useState(null);
    const [selectedCustomers2, setSelectedCustomers2] = useState(null);
    const [loading2, setLoading2] = useState(true);
    const [visibleRight, setVisibleRight] = useState(false);
    const [redirect, setRedirect] = useState(null);
    const [detallespedido, setDetallePedido] = useState(null);

    useEffect(() => {
        const customerService = new Pedidos();
        customerService.getCustomersLargeAdmin().then(data => { setCustomer2(data); setLoading2(false) });
        if(!localStorage.getItem('user_id')){
            setRedirect('/login');
            }
        if(localStorage.getItem("tipouser") == "CLIENTE"){
            setRedirect('/login');
            }
    }, []);

    const customer2TableHeader = (
        <div className="table-header">
            Pedidos
        </div>
    );

    const dateBodyTemplate = (data) => {
        return (
            <>
                {data.fecha}
            </>
        );
    };

    const mailBodyTemplate = (data) => {
        return (
            <>
                {data.email}
            </>
        );
    };

    const nombreUsuarioBodyTemplate = (data) => {
        return (
            <>
                {data.nombre}
            </>
        );
    };

    const estadoBodyTemplate = (data) => {
        var estado ="";
        if(data.estado==null || data.estado==="0"){
            estado = "GENERADO"
        } else {
            estado = "ENTREGADO"
        }
        return (
            <>
                {estado}
            </>
        );
    };

    const verBodyTemplate = (data) => {
        /* console.log(data) */
        return (
            <>
                <Button type="button" onClick={() => verDetallesMiPedido(data)} label="Ver Detalle" className="p-button-secondary" />
            </>
        );
    };

    const eliminarBodyTemplate = (data) => {
        /* console.log(data) */
        return (
            <>
                <Button type="button" onClick={() => eliminarPedido(data)} label="Eliminar Pedido" className="p-button-danger" />
            </>
        );
    };

    const cambiarBodyTemplate = (data) => {
        /* console.log(data) */
        return (
            <>
                <Button type="button" onClick={() => cambiarEstado(data)} label="Marcar como entregado" className="p-button-info" />
            </>
        );
    };

    

    const cambiarEstado=async(data)=>{
        var iddetalle = data.id;
        /* console.log(iddetalle); */ 
        localStorage.setItem('iddetalle', iddetalle)        
        await axios.get(UrlGral+'pedidos/?cambiar='+iddetalle+'&llave='+localStorage.getItem('llave')).then(response=>{
            /* console.log(response) */
        }).catch(error=>{
            console.log(error);
        })
        const customerService = new Pedidos();
        customerService.getCustomersLargeAdmin().then(data => setCustomer2(data));      
    }
    
    const verDetallesMiPedido=async(data)=>{
        var iddetalle = data.id;
        /* console.log(iddetalle); */
        localStorage.setItem('iddetalle', iddetalle)
        class DetallepedidoNuevo{
            getDetalle() {
                return axios.get(UrlGral+'pedidos/?verdetalle='+iddetalle+'&llave='+localStorage.getItem('llave'))
            .then(res => res.data);
            }
        }
        const detalle = new DetallepedidoNuevo();
        detalle.getDetalle().then(datadetalle => setDetallePedido(datadetalle));
        setVisibleRight(true);        
    }
    
    const eliminarPedido=async(data)=>{
        var iddetalle = data.id;
        localStorage.setItem('iddetalle', iddetalle)            
        await axios.get(UrlGral+'pedidos/?eliminarpedido='+iddetalle+'&llave='+localStorage.getItem('llave')).then(response=>{
            /* console.log(response) */
        }).catch(error=>{
            console.log(error);
        })
        const customerService = new Pedidos();
        customerService.getCustomersLargeAdmin().then(data => setCustomer2(data)); 
    }

    const nombreDetalleBodyTemplate = (datadetalle) => {
        return (
            <>
                {datadetalle.nombre}<br/>Variantes:<b>{datadetalle.variantes}</b>
            </>
        );
    };

    const marcaDetalleBodyTemplate = (datadetalle) => {
        return (
            <>
                {datadetalle.marca}
            </>
        );
    };

    const cantidadDetalleBodyTemplate = (datadetalle) => {
        return (
            <>
                {datadetalle.cantidad}
            </>
        );
    };

    const totalBodyTemplate = (data) => {
        return (
            <>
                $ {parseFloat(data.total_pedido).toFixed(2)}
            </>
        );
    };

    const precioDetalleBodyTemplate = (datadetalle) => {
        return (
            <>
                $ {parseFloat(datadetalle.precio).toFixed(2)}
            </>
        );
    };

    const subtotalDetalleBodyTemplate = (datadetalle) => {
        return (
            <>
                $ {parseFloat(datadetalle.subtotal).toFixed(2)}
            </>
        );
    };

    let total = 0;
    {detallespedido !== null &&
        detallespedido.map((item) => (                             
            total += parseFloat(item.subtotal)
        ))
    } 
    

    if(redirect){
        return <Redirect to={redirect}/>
    }

    return (
        
        <div className="p-grid table-demo">            
            <Sidebar className="detallespedidoadmin" style={{height: '100%'},{overflow: 'scroll'}}  visible={visibleRight} onHide={() => setVisibleRight(false)} baseZIndex={500} position="right">
                <h1 style={{ fontWeight: 'normal' }} >Detalle del Pedido</h1> 
                <DataTable 
                value={detallespedido} 
                className="verescritorio"
                dataKey="id" 
                onSelectionChange={(e) => setSelectedCustomers2(e.value)}                    
                emptyMessage="No customers found." 
                loading={loading2}
                footer={
                    <div className="p-grid">
                        <div className="p-col-8">
                        </div>
                        <div className="p-col-4">
                        Total pedido: ${parseFloat(total).toFixed(2)}
                        </div>
                    </div> 
                }
                >
                    <Column field="nombre" header="Producto" sortable body={nombreDetalleBodyTemplate}></Column>
                    <Column field="marca" header="Marca" sortable body={marcaDetalleBodyTemplate}></Column>
                    <Column field="Cant" header="Cant" sortable body={cantidadDetalleBodyTemplate}></Column>
                    <Column className="texto-derecha" field="precio" header="Precio" sortable body={precioDetalleBodyTemplate}></Column>
                    <Column className="texto-derecha" field="subtotal" header="Subtotal" sortable body={subtotalDetalleBodyTemplate}></Column>
                </DataTable>   
                <h1 className="vermobile" style={{ fontWeight: 'normal' },{width: "100% !important"}}>Detalle del Pedido</h1> 
                <DataTable 
                value={detallespedido} 
                className="vermobile"
                dataKey="id" 
                onSelectionChange={(e) => setSelectedCustomers2(e.value)}                    
                emptyMessage="No customers found." 
                loading={loading2}
                footer={
                    <div className="p-grid">
                        <div className="p-col-8">
                        </div>
                        <div className="p-col-4">
                        Total pedido: ${parseFloat(total).toFixed(2)}
                        </div>
                    </div> 
                }
                >
                    <Column field="nombre" header="Producto" sortable body={nombreDetalleBodyTemplate}></Column>
                    <Column field="marca" header="Marca" sortable body={marcaDetalleBodyTemplate}></Column>
                    <Column field="Cant" header="Cant" sortable body={cantidadDetalleBodyTemplate}></Column>
                    <Column field="precio" header="Precio" sortable body={precioDetalleBodyTemplate}></Column>
                    <Column field="subtotal" header="Subtotal" sortable body={subtotalDetalleBodyTemplate}></Column>
                </DataTable>                
            </Sidebar>
{/*             <Sidebar className="detallespedidoadmin vermobile"  visible={visibleRight} onHide={() => setVisibleRight(false)} baseZIndex={500} position="right">
                             
            </Sidebar> */}
            <div className="p-col-12">             
                <DataTable value={customer2} className="verescritorio"
                    paginator rows={10} rowsPerPageOptions={[5, 10, 25]} dataKey="id" rowHover selection={selectedCustomers2} 
                    onSelectionChange={(e) => setSelectedCustomers2(e.value)}
                    emptyMessage="No customers found." loading={loading2} header={customer2TableHeader}>
                    <Column className="pedido_dato" field="fecha" header="Fecha" sortable body={dateBodyTemplate}></Column>
                    <Column className="pedido_dato" field="email" header="Mail" sortable body={mailBodyTemplate}></Column>
                    <Column className="pedido_dato" field="nombre" header="Nombre" sortable body={nombreUsuarioBodyTemplate}></Column>
                    <Column className="pedido_dato" field="total_pedido" header="Total" sortable body={totalBodyTemplate}></Column>
                    <Column className="pedido_dato" field="estado" header="Estado" sortable body={estadoBodyTemplate}></Column>
                    <Column className="pedido_dato" field="vew" header="Ver" body={verBodyTemplate}></Column>
                    <Column className="pedido_dato" field="cambiar" header="Cambiar" body={cambiarBodyTemplate}></Column>
                    <Column className="pedido_dato" field="delete" header="Eliminar" body={eliminarBodyTemplate}></Column>
                </DataTable>
                <DataTable value={customer2} className="vermobile"
                    paginator rows={10} rowsPerPageOptions={[5, 10, 25]} dataKey="id" rowHover selection={selectedCustomers2} 
                    onSelectionChange={(e) => setSelectedCustomers2(e.value)}
                    emptyMessage="No customers found." loading={loading2} header={customer2TableHeader}>
                    <Column className="pedido_dato" field="fecha" header="Fecha" sortable body={dateBodyTemplate}></Column>
                    <Column className="pedido_dato" field="email" header="Mail" sortable body={mailBodyTemplate}></Column>
                    <Column className="pedido_dato" field="nombre" header="Nombre" sortable body={nombreUsuarioBodyTemplate}></Column>
                    <Column className="pedido_dato" field="total_pedido" header="Total" sortable body={totalBodyTemplate}></Column>
                    <Column className="pedido_dato" field="estado" header="Estado" sortable body={estadoBodyTemplate}></Column>
                    <Column className="pedido_dato" field="vew" header="Ver" body={verBodyTemplate}></Column>
                    <Column className="pedido_dato" field="cambiar" header="Cambiar" body={cambiarBodyTemplate}></Column>
                    <Column className="pedido_dato" field="delete" header="Eliminar" body={eliminarBodyTemplate}></Column>
                </DataTable>
            </div>
        </div>
    )
}
