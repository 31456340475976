import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Redirect } from 'react-router-dom';
import { Toast } from 'primereact/toast';

export const Login = () => {    

    const [datosUser, setDatos] = useState({
        user: '',
        pass: ''
    });
    
    if(window.location.hostname === "localhost" || window.location.hostname === "local.roqasoft.com"){     
        /* console.log("HOST="+window.location.hostname)    */
        localStorage.setItem("UrlGral", "http://local.roqasoft.com:12280/faros_gustavo/API/")
    } else if(window.location.hostname === "roqasoft"){
        localStorage.setItem("UrlGral", "https://pedidos.uniblancsrl.com/API/")
    }else {
        localStorage.setItem("UrlGral", "https://pedidos.uniblancsrl.com/API/")
    }
    
    const [redirect, setRedirect] = useState(null);
    const toast = useRef();

    const handleInputChange = (event) => {
        setDatos({
            ...datosUser,
            [event.target.name] : event.target.value
        })
    }

    const showError = () => {
        toast.current.show({ severity: 'error', summary: 'ERROR', detail: 'Usuario o contraseña erróneos.', life: 5000 });
    };
    localStorage.setItem('estadologin', "INGRESAR");
    const Logear=async()=>{        
        await axios.get(localStorage.getItem("UrlGral")+"clientes/comprobar-login/?user="+datosUser.user+"&pass="+datosUser.pass)
        .then(response=>{                       
          /* console.log(response.data) */
          if(response.data == "MAL DATOS"){
            showError()
            /* window.location.reload(); */
            /* setRedirect('/login'); */
          } else {
            localStorage.setItem('user_id', response.data.id_usuario);
            localStorage.setItem('llave', response.data.llave);  
            localStorage.setItem('user_lista', response.data.lista); 
            localStorage.setItem('nombreuser', response.data.nombre);     
            localStorage.setItem('apellidouser', response.data.apellido);  
            localStorage.setItem('descuentousuario', response.data.descuento);
            localStorage.setItem('razon_socialusuario', response.data.razon_social);

            /*
            var now = new Date();
            //now.setTime(now.getTime() + (1*60*60*1000));
            now.setTime(now.getTime() + (10*1000));
            localStorage.setItem('tiempo_login', now.getTime());    
            */          
            
            if(response.data.tipo == "0"){
                localStorage.setItem('marcafiltrada', 1)
                localStorage.setItem('rubrofiltrado', 1)
                localStorage.setItem('tipouser', "CLIENTE"); 
                setRedirect('/catalogo');
                window.location.reload();
            } else if (response.data.tipo == "1") {
                localStorage.setItem('tipouser', "ADMIN"); 
                setRedirect('/verpedidos');
                window.location.reload();
            }            
          }          
        }).catch(error=>{
            setRedirect('/catalogo')
         console.log(error);
        })
      }


if(redirect){
    return <Redirect to={redirect}/>
}

    return (
        <div className="login" style={{ width: '15%' }}>
            <div className="p-col-12 p-md-6 loginform">
                <div className="card p-fluid">
                    <Toast ref={toast} />
                    <div className="p-col-12 logologin">
                        <img alt="Logo" src="assets/img/logo.png" />
                    </div>
                    
                    <h5>INGRESAR A SISTEMA DE PEDIDOS</h5>
                    <div className="p-field p-grid">
                        <label htmlFor="user" className="p-col-12 p-mb-3 p-md-3 p-mb-md-0">Usuario</label>
                        <div className="p-col-12 p-md-9">
                            <InputText name="user" id="user" type="text" onChange={handleInputChange}/>
                        </div>
                    </div>
                    <div className="p-field p-grid">
                        <label htmlFor="pass" className="p-col-12 p-mb-3 p-md-3 p-mb-md-0">Clave</label>
                        <div className="p-col-12 p-md-9">
                            <InputText name="pass" id="pass" type="password" onChange={handleInputChange}/>
                        </div>
                    </div>
                    <div className="p-field p-grid">
                        
                        <div className="p-col-12 p-md-12">
                            <Button type="button" onClick={() => Logear()} label="Ingresar" className="p-button-secondary" />
                            {/* <Button type="button" onClick={() => Salir()} label="Salir" className="p-button-secondary" /> */}
                        </div> 
                    </div>                    
                </div>
            </div>
        </div>
    )
}