import axios from 'axios';
const UrlGral = localStorage.getItem("UrlGral")


export class Productos {

    getProductsSmall() {
        return axios.get(UrlGral+'productos/?catalogo=1&llave='+localStorage.getItem('llave')).then(res => res.data);
    }

    getProducts() {
        return axios.get(UrlGral+'productos/?admin='+localStorage.getItem('tipouser')+'&catalogo=1&llave='+localStorage.getItem('llave')).then(res => res.data);
    }

    getProductsWithOrdersSmall() {
        return axios.get(UrlGral+'productos/?catalogo=1&llave='+localStorage.getItem('llave')).then(res => res.data);
    }
}