import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Redirect } from 'react-router-dom';
import { Sidebar } from 'primereact/sidebar';
import { Toast } from 'primereact/toast';


export const ClientesComponente = () => {

    let emptyClient = {
        id: '',
        nombre: '',
        apellido: '',
        razon_social: '',
        email: '',
        telefono: '',
        cuit: '',
        tipo: '',
        localidad: '',
        prvincia: '',
        domicilio: '',
        lista: '',
        pass: ''
    };

    const UrlGral = localStorage.getItem("UrlGral")


    const [cliente, setCliente] = useState(emptyClient);
    const [clientes, setClientes] = useState(null);
    const [selectedCustomers1, setSelectedCustomers1] = useState(null);
    const [visibleRight, setVisibleRight] = useState(false);
    const [globalFilter1, setGlobalFilter1] = useState('');
    const dt = useRef(null);
    const [redirect, setRedirect] = useState(null);
    const toast = useRef();

    
    useEffect(() => {
        class CargarClientes{
            getCargarClientes() {
                return axios.get(UrlGral+'clientes/?admin=1&clientes=1&llave='+localStorage.getItem('llave')).then(res => res.data);
            }
        }
        const clientex = new CargarClientes();
        clientex.getCargarClientes().then(data => setClientes(data));
        if(!localStorage.getItem('user_id')){
        setRedirect('/login');
        }
        if(localStorage.getItem("tipouser") == "CLIENTE"){
            setRedirect('/login');
        }
    }, []);

    const idClienteBodyTemplate = (rowData) => {
        return (
            <>
                
                {rowData.id}
            </>
        );
    }

    const nombreClienteBodyTemplate = (rowData) => {
        return (
            <>
                
                {rowData.nombre}
            </>
        );
    }

    const apellidoClienteBodyTemplate = (rowData) => {
        return (
            <>
                
                {rowData.apellido}
            </>
        );
    }

    const razonClienteBodyTemplate = (rowData) => {
        return (
            <>
                
                {rowData.razon_social}
            </>
        );
    }

    const emailClienteBodyTemplate = (rowData) => {
        return (
            <>
                
                {rowData.email}
            </>
        );
    }

    const listaClienteBodyTemplate = (rowData) => {
        return (
            <>
                
                {rowData.lista}
            </>
        );
    }

    const tipoClienteBodyTemplate = (rowData) => {
        if(rowData.tipo == "2"){
            var variable = 'API'
        } else if(rowData.tipo == "1"){
            var variable = 'ADMIN'
        } else {
            var variable = 'CLIENTE'
        }
        return (
            <>
                
                {variable}
                
            </>
        );
    }

    const telefonoClienteBodyTemplate = (rowData) => {
        return (
            <>
                
                {rowData.telefono}
            </>
        );
    }

    const domicilioClienteBodyTemplate = (rowData) => {
        return (
            <>
                
                {rowData.domicilio +" - "+ rowData.localidad +" - "+ rowData.provincia} 
            </>
        );
    }

    const cuitClienteBodyTemplate = (rowData) => {
        return (
            <>
                
                {rowData.cuit}
            </>
        );
    }

    const editarClienteBodyTemplate = (rowData) => {
        return (
            <>
                <Button onClick={() => editarCliente(rowData)} icon="pi pi-user-edit" className="p-button-rounded p-button-info p-mr-2 p-mb-2" />
            </>
        );
    }

    const customer1TableHeader = (
        <div className="table-header">
            <div className="p-grid">
                <div className="p-col-12">
                    <span className="p-input-icon-left">                
                        <i className="pi pi-search" />
                        <InputText onChange={(e) => setGlobalFilter1(e.target.value)} placeholder="Buscar" />
                    </span>
                </div>
            </div>
        </div>
    );

    const editarCliente = (cliente) => {
        setCliente({ ...cliente });
        setVisibleRight(true)
    }

    const [datosCliente, setDatos] = useState({
        id: null,
        nombre: '',
        apellido: '',
        razon_social: ''
    })    

    const handleInputChange = (event) => {
        /* event.preventDefault(); */
        setCliente({
            ...cliente,
            [event.target.name] : event.target.value
        })
    }

    const showInfo = () => {
        toast.current.show({ severity: 'success', summary: 'Usuario modificado', detail: 'Se modificó correctamente el usuario', life: 5000 });
    };

    const guardarUsuarioEditado=async()=>{
        
        var id_usuario = localStorage.getItem('user_id')
        var fpedido = new FormData();
        fpedido.append("id", cliente.id); 
        fpedido.append("nombre", cliente.nombre); 
        fpedido.append("apellido", cliente.apellido); 
        fpedido.append("razon_social", cliente.razon_social); 
        fpedido.append("telefono", cliente.telefono); 
        fpedido.append("cuit", cliente.cuit); 
        fpedido.append("tipo", cliente.tipo); 
        fpedido.append("provincia", cliente.provincia); 
        fpedido.append("localidad", cliente.localidad); 
        fpedido.append("domicilio", cliente.domicilio); 
        fpedido.append("email", cliente.email);
        fpedido.append("pass", cliente.pass); 
        fpedido.append("lista", cliente.lista);         
        fpedido.append("METHOD", "PUT");
        axios.post(UrlGral+"clientes/?id="+cliente.id+"&id_usuario="+id_usuario+"&llave="+localStorage.getItem('llave'), fpedido)
        .then(response=>{
          /* console.log(response) */
          class CargarClientes{
            getCargarClientes() {
                return axios.get(UrlGral+'clientes/?admin=1&clientes=1&llave='+localStorage.getItem('llave')).then(res => res.data);
            }
        }
        const clientex = new CargarClientes();
        clientex.getCargarClientes().then(data => setClientes(data));
          
        }).catch(error=>{
         console.log(error);
        })            
        setVisibleRight(false)   
        showInfo()        
      }      
    
      if(redirect){
        return <Redirect to={redirect}/>
    }

    return (
        <div className="p-grid crud-demo">
            <Toast ref={toast} />
            <div className="p-col-12">
                <div className="p-grid">
                    <div className="p-col-12">
                        <DataTable ref={dt} value={clientes} selection={selectedCustomers1} onSelectionChange={(e) => setSelectedCustomers1(e.value)}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="verescritorio"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"                            
                            globalFilter={globalFilter1} emptyMessage="No products found." header={customer1TableHeader}>
                            {/* <Column className="datoproducto" field="id" header="Id Cliente" sortable body={idClienteBodyTemplate}></Column> */}
                            <Column style={{ width: '15%' }} className="datoproducto" field="razon_social" header="Razón Social" sortable body={razonClienteBodyTemplate}></Column>
                            <Column style={{ width: '15%' }} className="datoproducto" field="email" header="Email" sortable body={emailClienteBodyTemplate}></Column>
                            <Column style={{ width: '34%' }} className="datoproducto" field="domicilio" header="Domicilio" sortable body={domicilioClienteBodyTemplate}></Column>
                            <Column style={{ width: '10%' }} className="datoproducto" field="telefono" header="Telefono" sortable body={telefonoClienteBodyTemplate}></Column>
                            <Column style={{ width: '10%' }} className="datoproducto" field="cuit" header="Cuit" sortable body={cuitClienteBodyTemplate}></Column>
                            <Column style={{ width: '6%' }} className="datoproducto" field="tipo" header="Tipo" sortable body={tipoClienteBodyTemplate}></Column>   
                            <Column style={{ width: '5%' },{textAlign: "center"}} className="datoproducto" field="lista" header="Lista" body={listaClienteBodyTemplate}></Column>                        
                            {/* <Column style={{ width: '10%' }} className="datoproducto" field="nombre" header="Nombre" sortable body={nombreClienteBodyTemplate}></Column>                            
                            <Column style={{ width: '10%' }} className="datoproducto" field="apellido" header="Apellido" sortable body={apellidoClienteBodyTemplate}></Column> */}
                            <Column style={{ width: '5%' }} className="datoproducto" field="editar" header="Editar" body={editarClienteBodyTemplate}></Column>
                        </DataTable>
                        <DataTable ref={dt} value={clientes} selection={selectedCustomers1} onSelectionChange={(e) => setSelectedCustomers1(e.value)}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="vermobile"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"                            
                            globalFilter={globalFilter1} emptyMessage="No products found." header={customer1TableHeader}>
                            {/* <Column className="datoproducto" field="id" header="Id Cliente" sortable body={idClienteBodyTemplate}></Column> */}
                            <Column style={{ width: '15%' }} className="datoproducto" field="razon_social" header="Razón Social" sortable body={razonClienteBodyTemplate}></Column>
                            <Column style={{ width: '15%' }} className="datoproducto" field="email" header="Email" sortable body={emailClienteBodyTemplate}></Column>
                            <Column style={{ width: '34%' }} className="datoproducto" field="domicilio" header="Domicilio" sortable body={domicilioClienteBodyTemplate}></Column>
                            <Column style={{ width: '10%' }} className="datoproducto" field="telefono" header="Telefono" sortable body={telefonoClienteBodyTemplate}></Column>
                            <Column style={{ width: '10%' }} className="datoproducto" field="cuit" header="Cuit" sortable body={cuitClienteBodyTemplate}></Column>
                            <Column style={{ width: '6%' }} className="datoproducto" field="tipo" header="Tipo" sortable body={tipoClienteBodyTemplate}></Column>   
                            <Column style={{ width: '5%' },{textAlign: "center"}} className="datoproducto" field="lista" header="Lista" body={listaClienteBodyTemplate}></Column>                        
                            {/* <Column style={{ width: '10%' }} className="datoproducto" field="nombre" header="Nombre" sortable body={nombreClienteBodyTemplate}></Column>                            
                            <Column style={{ width: '10%' }} className="datoproducto" field="apellido" header="Apellido" sortable body={apellidoClienteBodyTemplate}></Column> */}
                            <Column style={{ width: '5%' }} className="datoproducto" field="editar" header="Editar" body={editarClienteBodyTemplate}></Column>
                        </DataTable>
                    </div> 
                    <Sidebar className="sidebar-cliente" visible={visibleRight} onHide={() => setVisibleRight(false)} baseZIndex={500} position="right">
                        <h1 style={{ fontWeight: 'normal' }}>Editar Cliente</h1> 
                        <div className="p-col-12">
                            <div className="p-grid">
                                <input type="hidden" id="id" name="id" value={cliente.id}/>
                                <div className="p-col-6">Razón Social: <InputText className="formcliente" name="razon_social" value={cliente.razon_social} onChange={handleInputChange} /></div>
                                <div className="p-col-6">Cuit: <InputText className="formcliente" name="cuit" value={cliente.cuit} onChange={handleInputChange} /></div>                                
                                <div className="p-col-4">Nombre: <InputText className="formcliente" name="nombre" value={cliente.nombre} onChange={handleInputChange}/></div>
                                <div className="p-col-4">Apellido: <InputText className="formcliente" name="apellido" value={cliente.apellido} onChange={handleInputChange} /></div>                                
                                <div className="p-col-4">Telefono: <InputText className="formcliente" name="telefono" value={cliente.telefono} onChange={handleInputChange} /></div>
                                <div className="p-col-4">Domicilio: <InputText className="formcliente" name="domicilio" value={cliente.domicilio} onChange={handleInputChange} /></div>
                                <div className="p-col-4">Localidad: <InputText className="formcliente" name="localidad" value={cliente.localidad} onChange={handleInputChange} /></div>
                                <div className="p-col-4">Provincia: <InputText className="formcliente" name="provincia" value={cliente.provincia} onChange={handleInputChange} /></div>
                                <div className="p-col-4">Email: <InputText className="formcliente" name="email" value={cliente.email} onChange={handleInputChange} /></div>
                                <div className="p-col-4">Password: <InputText className="formcliente" name="pass" value={cliente.pass} onChange={handleInputChange} /></div>  
                                <div className="p-col-2">Lista: <InputText className="formcliente" name="lista" value={cliente.lista} onChange={handleInputChange} /></div>     
                                <div className="p-col-2">
                                Tipo:
                                <select className="formcliente" name="tipo" value={cliente.tipo} onChange={handleInputChange}>
                                    <option value="0">CLIENTE</option>
                                    <option value="1">ADMIN</option>
                                    <option value="2">API</option>
                                </select>
                                </div>         
                                                 
                                <div className="p-col-12">
                                    <Button className="p-button p-button-info p-mr-2 botoncantidad" onClick={guardarUsuarioEditado}>GUARDAR</Button>
                                </div>
                            </div>
                        </div>
                    </Sidebar>                   
                </div>
            </div>
        </div>
    );
}
