import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Redirect } from 'react-router-dom';
import { Sidebar } from 'primereact/sidebar';
import { Toast } from 'primereact/toast';


export const VerMarcasComponente = () => {

    let emptyMarca = {
        id: '',
        img: '',
        nombre: '',
        url: ''
    };

    const UrlGral = localStorage.getItem("UrlGral")


    const [marca, setMarca] = useState(emptyMarca);
    const [marcas, setMarcas] = useState(null);
    const [selectedCustomers1, setSelectedCustomers1] = useState(null);
    const [visibleRight, setVisibleRight] = useState(false);
    const [globalFilter1, setGlobalFilter1] = useState('');
    const dt = useRef(null);
    const [redirect, setRedirect] = useState(null);
    const toast = useRef();

    
    useEffect(() => {
        class MarcasAdmin{
            getMarcaAdmin() {
                return axios.get(UrlGral+'marcas/?admin=1&llave='+localStorage.getItem('llave')).then(res => res.data);
            }
        }
        const marca = new MarcasAdmin();
        marca.getMarcaAdmin().then(data => setMarcas(data));
        if(!localStorage.getItem('user_id')){
        setRedirect('/login');
        }
        if(localStorage.getItem("tipouser") == "CLIENTE"){
            setRedirect('/login');
        }
    }, []);

    const idMarca = (rowData) => {
        return (
            <>
                <span className="p-column-title">Id</span>
                {rowData.id}
            </>
        );
    }

    const imgMarca = (rowData) => {
        return (
            <>
                <div className="contenedorimgmarca">
                    <img src={`assets/img/marcas/${rowData.img}`} alt={rowData.img} className="brand-image" />
                </div>
            </>
        );
    }

    const nombreClienteBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.nombre}
            </>
        );
    }

    const urlClienteBodyTemplate = (rowData) => {
        return (
            <>
                {rowData.url}
            </>
        );
    }

    const editarMarcaBodyTemplate = (rowData) => {
        return (
            <>
                <Button onClick={() => editarMarca(rowData)} icon="pi pi-pencil" className="p-button-rounded p-button-info p-mr-2 p-mb-2" />
            </>
        );
    }

    const customer1TableHeader = (
        <div className="table-header">
            <div className="p-grid">
                <div className="p-col-12">
                    <span className="p-input-icon-left">                
                        <i className="pi pi-search" />
                        <InputText onChange={(e) => setGlobalFilter1(e.target.value)} placeholder="Buscar" />
                    </span>
                </div>
            </div>
        </div>
    );

    const editarMarca = (marca) => {
        setMarca({ ...marca });
        setVisibleRight(true)
    }

  /*   const [datosCliente, setDatos] = useState({
        id: null,
        nombre: '',
        apellido: '',
        razon_social: ''
    })     */

    const handleInputChange = (event) => {
        /* event.preventDefault(); */
        setMarca({
            ...marca,
            [event.target.name] : event.target.value
        })
    }

    const showInfo = () => {
        toast.current.show({ severity: 'success', summary: 'Marca modificada', detail: 'Se modificó correctamente la marca', life: 2000 });
    };

    const guardarMarcaEditada=async()=>{        
        var fpedido = new FormData();
        fpedido.append("id", marca.id); 
        fpedido.append("nombre", marca.nombre);  
        fpedido.append("url", marca.url);     
        fpedido.append("imagenmarca", archivo);     
        fpedido.append("METHOD", "PUT");
        axios.post(UrlGral+"marcas/?id="+marca.id+"&llave="+localStorage.getItem('llave'), fpedido, {headers: {'Content-Type': 'multipart/form-data'} })
        .then(response=>{
         /*  console.log(response)    */  
          class MarcasAdmin{
                getMarcaAdmin() {
                    return axios.get(UrlGral+'marcas/?admin=1&llave='+localStorage.getItem('llave')).then(res => res.data);
                }
            }
            const marca = new MarcasAdmin();
            marca.getMarcaAdmin().then(data => setMarcas(data));    
        }).catch(error=>{
         console.log(error);
        })            
        setVisibleRight(false)   
        showInfo()        
    }     
    

    const [archivo, setArchivo] = useState(null);

    const subirImagen=(e)=>{
        setArchivo(e);        
    }


    if(redirect){
        return <Redirect to={redirect}/>
    }

    
    return (
        <div className="p-grid crud-demo">
            <Toast ref={toast} />
            <div className="p-col-12">
                <div className="p-grid">
                    <div className="p-col-12">
                        <DataTable ref={dt} value={marcas} selection={selectedCustomers1} onSelectionChange={(e) => setSelectedCustomers1(e.value)}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="verescritorio"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"                            
                            globalFilter={globalFilter1} emptyMessage="No products found." header={customer1TableHeader}>   
                            <Column className="datoproducto imgbrandcolumna" field="img" header="Img Marca" sortable body={imgMarca}></Column>
                            <Column className="datoproducto nombremarcacolumna" field="nombre" header="Nombre" sortable body={nombreClienteBodyTemplate}></Column>    
                            <Column className="datoproducto nombremarcacolumna" field="url" header="Url" sortable body={urlClienteBodyTemplate}></Column>                            
                            <Column className="datoproducto editarmarcacolumna" field="editar" header="Editar" body={editarMarcaBodyTemplate}></Column>
                        </DataTable>
                        <DataTable ref={dt} value={marcas} selection={selectedCustomers1} onSelectionChange={(e) => setSelectedCustomers1(e.value)}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="vermobile"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"                            
                            globalFilter={globalFilter1} emptyMessage="No products found." header={customer1TableHeader}>   
                            <Column className="datoproducto imgbrandcolumna" field="img" header="Img Marca" sortable body={imgMarca}></Column>
                            <Column className="datoproducto nombremarcacolumna" field="nombre" header="Nombre" sortable body={nombreClienteBodyTemplate}></Column>    
                            {/* <Column className="datoproducto nombremarcacolumna" field="url" header="Url" sortable body={urlClienteBodyTemplate}></Column>     */}                        
                            <Column className="datoproducto editarmarcacolumna" field="editar" header="Editar" body={editarMarcaBodyTemplate}></Column>
                        </DataTable>
                    </div> 
                    <Sidebar className="sidebar-marca" visible={visibleRight} onHide={() => setVisibleRight(false)} baseZIndex={500} position="right">
                        <h1 style={{ fontWeight: 'normal' }}>Editar Marca</h1> 
                        <div className="p-col-12">
                            <div className="p-grid">
                                <input type="hidden" id="id" name="id" value={marca.id}/>  
                                <div className="p-col-3"><img src={`assets/img/marcas/${marca.img}`} alt={marca.img} className="product-image" /></div> 
                                <div className="p-col-9"></div>    
                                <div className="p-col-12"><input type="file" name="imgmarca" onChange={(e)=>subirImagen(e.target.files[0])} /></div>             
                                <div className="p-col-6">Nombre: <InputText className="formmarca" name="nombre" value={marca.nombre} onChange={handleInputChange}/></div> 
                                <div className="p-col-6">Url Catálogo: <InputText className="formmarca" name="url" value={marca.url} onChange={handleInputChange}/></div>                          
                                <div className="p-col-12">
                                    <Button className="p-button p-button-info p-mr-2" onClick={guardarMarcaEditada}>GUARDAR</Button>
                                </div>
                            </div>
                        </div>
                    </Sidebar>                   
                </div>
            </div>
        </div>
    );
}
