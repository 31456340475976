import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Pedidos } from '../service/Pedidos';
import { DetallePedido } from '../service/DetallesPedidos';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import {Table} from 'react-bootstrap'
import { Redirect } from 'react-router-dom';

export const MisPedidos = () => {

    const UrlGral = localStorage.getItem("UrlGral")

    const [customer2, setCustomer2] = useState(null);
    const [selectedCustomers2, setSelectedCustomers2] = useState(null);
    const [loading2, setLoading2] = useState(true);
    const [visibleRight, setVisibleRight] = useState(false);
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const dt = useRef(null);
    const [redirect, setRedirect] = useState(null);

    useEffect(() => {
        const customerService = new Pedidos();
        customerService.getCustomersLarge().then(data => { setCustomer2(data); setLoading2(false) });
        if(!localStorage.getItem('user_id')){
            setRedirect('/login');
            }
    }, []);


    useEffect(() => {
        if(displayConfirmation === false){
            localStorage.setItem('iddetalle', null)
        }
    }, [displayConfirmation]);



    const customer2TableHeader = (
        <div className="table-header">
            Mis Pedidos
        </div>
    );

    const dateBodyTemplate = (data) => {
        return (
            <>
                {data.fecha}
            </>
        );
    };

    const idBodyTemplate = (data) => {
        return (
            <>
                {data.id}
            </>
        );
    };

    

    const verBodyTemplate = (data) => {
        return (
            <>
                <Button type="button" onClick={() => verDetallesMiPedido(data)} label="Ver Detalle" className="p-button-secondary" />
            </>
        );
    };
         
    const [detallespedido, setDetallePedido] = useState(null);
    const verDetallesMiPedido=async(data)=>{
        var iddetalle = data.id;
        localStorage.setItem('iddetalle', iddetalle)
        class DetallepedidoNuevo{
            getDetalle() {
                return axios.get(UrlGral+'pedidos/?verdetalle='+iddetalle+'&llave='+localStorage.getItem('llave'))
            .then(res => res.data);
            }
        }        
        const detalle = new DetallepedidoNuevo();
        detalle.getDetalle().then(datadetalle => setDetallePedido(datadetalle)); 
        
        
        setVisibleRight(true);
    }

    let total = 0;
    {detallespedido !== null &&
        detallespedido.map((item) => (                             
            total += parseFloat(item.subtotal)/* .toFixed(2) */
        ))
        total = parseFloat(total).toFixed(2);
    } 
    

    const idDetalleBodyTemplate = (datadetalle) => {
        return (
            <>
                {datadetalle.id_detalle}
            </>
        );
    };

    const nombreDetalleBodyTemplate = (datadetalle) => {
        return (
            <>  
                <b>{datadetalle.marca}</b> - {datadetalle.nombre}
            </>
        );
    };

    const nombreDetalleBodyTemplate2 = (datadetalle) => {
        return (
            <>  
                Código: <b>{datadetalle.codigo}</b><br/>Marca: <b>{datadetalle.marca}</b><br/>Detalle: {datadetalle.nombre}
            </>
        );
    };

    const cantidadDetalleBodyTemplate2 = (datadetalle) => {
        return (
            <>
                Cantidad:: <b>{datadetalle.cantidad}</b><br/>Precio: <b>${datadetalle.precio}</b><br/>Subtotal: ${datadetalle.subtotal}
            </>
        );
    };

    /* const marcaDetalleBodyTemplate = (datadetalle) => {
        return (
            <>
                
            </>
        );
    }; */

    const cantidadDetalleBodyTemplate = (datadetalle) => {
        return (
            <>
                {datadetalle.cantidad}
            </>
        );
    };



    const totalBodyTemplate = (data) => {
        return (
            <>
                $ {parseFloat(data.total_pedido).toFixed(2)}
            </>
        );
    };

    const precioDetalleBodyTemplate = (datadetalle) => {
        return (
            <>
                $ {parseFloat(datadetalle.precio).toFixed(2)}
            </>
        );
    };

    const subtotalDetalleBodyTemplate = (datadetalle) => {
        return (
            <>
                $ {parseFloat(datadetalle.subtotal).toFixed(2)}
            </>
        );
    };

    const variantesDetalleBodyTemplate = (datadetalle) => {
        return (
            <>
                {datadetalle.variantes}
            </>
        );
    };


    const ClonarPedido=()=>{
        let iddetalle = localStorage.getItem('iddetalle');
        if(!iddetalle){
            alert('El pedido no puede ser clonado');
        }else{            
        var fpedido = new FormData();    
        fpedido.append("id_usuario", localStorage.getItem('user_id'));
        fpedido.append("clonar", localStorage.getItem('iddetalle'));
        fpedido.append("METHOD", "POST");
        axios.post(UrlGral+"pedidos/", fpedido)
        .then(response=>{
          /* console.log(response) */
          setRedirect('/catalogo');
        }).catch(error=>{
         /* console.log(error); */
        })
        setDisplayConfirmation(false)
        }
      }

    const confirmationDialogFooter = (
        <>
            <Button type="button" label="Si" icon="pi pi-check" onClick={() => ClonarPedido()} className="p-button-text" />
            <Button type="button" label="No" icon="pi pi-times" onClick={() => setDisplayConfirmation(false)} className="p-button-text" autoFocus />
        </>
    )

    if(redirect && redirect != "/mispedidos"){
        return <Redirect to={redirect}/>
    }

    return (
        
        <div className="p-grid table-demo">            
            <Sidebar visible={visibleRight} style={{height: '100%'},{overflow: 'scroll'}} onHide={() => setVisibleRight(false)} baseZIndex={500} className="sidebardetallemispedidos" position="right">
                <h1 style={{ fontWeight: 'normal' }}>Detalle del Pedido</h1> 
                <div className="verescritorio">
                    <DataTable 
                    value={detallespedido} 
                    className="tabladetallemispedidos"
                    dataKey="id" 
                    onSelectionChange={(e) => setSelectedCustomers2(e.value)}                        
                    emptyMessage="No customers found." 
                    loading={loading2} 
                    footer={
                        <div className="p-grid">
                            <div className="p-col-8">
                            </div>
                            <div className="p-col-4">
                            Total pedido: ${total}
                            </div>
                        </div> 
                    }
                    /* header={customer2TableHeader} */>
                        {/* <Column field="name" header="Name" sortable body={nameBodyTemplate}></Column> */}
                        <Column className="letra12" field="nombre" header="Producto" sortable body={nombreDetalleBodyTemplate}></Column>
                        {/* <Column className="letra12" field="marca" header="Marca" sortable body={marcaDetalleBodyTemplate}></Column> */}
                        <Column className="letra12" field="cantidad" header="Cantidad" sortable body={cantidadDetalleBodyTemplate}></Column>
                        <Column className="letra12 texto-derecha" field="precio" header="Precio" sortable body={precioDetalleBodyTemplate}></Column>
                        <Column className="letra12 texto-derecha" field="subtotal" header="Subtotal" sortable body={subtotalDetalleBodyTemplate}></Column>
                        <Column className="letra12" field="variantes" header="Variantes" sortable body={variantesDetalleBodyTemplate}></Column>
                        {/* <Column field="status" header="Status" sortable body={statusBodyTemplate}></Column>
                        <Column field="activity" header="Activity" sortable body={activityBody}></Column> */}
                    </DataTable>
                </div>
                <div className="vermobile">
                    <DataTable value={detallespedido} className="tabladetallemispedidos"
                    dataKey="id" onSelectionChange={(e) => setSelectedCustomers2(e.value)}
                        emptyMessage="No customers found." loading={loading2} /* header={customer2TableHeader} */>
                        <Column className="letra12 detallemispedidos" field="nombre" header="Producto" sortable body={nombreDetalleBodyTemplate2}></Column>
                        <Column className="letra12 detallemispedidos" field="cantidad" header="Cantidad" sortable body={cantidadDetalleBodyTemplate2}></Column>
                        <Column className="letra12 detallemispedidos" field="variantes" header="Variantes" sortable body={variantesDetalleBodyTemplate}></Column>
                        {/* <Column className="letra12" field="precio" header="Precio" sortable body={precioDetalleBodyTemplate}></Column>
                        <Column className="letra12" field="subtotal" header="Subtotal" sortable body={subtotalDetalleBodyTemplate}></Column> */}
                    </DataTable>
                </div>
                <Button  type="button" onClick={() => setDisplayConfirmation(true)} label="Realizar el mismo pedido" className="p-button-success footer_clonar" style={{marginRight: '.25em'}} />
                <Button  type="button" onClick={() => setVisibleRight(false)} label="Salir del detalle" className="p-button-secondary footer_clonar" />
            </Sidebar>
            <div className="p-col-12">               
                <DataTable value={customer2} paginator className="tablamispedidos"
                    rows={20} dataKey="id" rowHover selection={selectedCustomers2} 
                    onSelectionChange={(e) => setSelectedCustomers2(e.value)}
                    emptyMessage="No customers found." loading={loading2} header={customer2TableHeader}>
                    {/* <Column field="name" header="Name" sortable body={nameBodyTemplate}></Column> */}
                    <Column className="pedido_dato" field="fecha" header="Fecha" sortable body={dateBodyTemplate}></Column>
                    <Column className="pedido_dato" field="total_pedido" header="Total" sortable body={totalBodyTemplate}></Column>
                    <Column className="pedido_dato" field="vew" header="Ver" body={verBodyTemplate}></Column>
                    {/* <Column field="status" header="Status" sortable body={statusBodyTemplate}></Column>
                    <Column field="activity" header="Activity" sortable body={activityBody}></Column> */}
                </DataTable>
            </div>
            <Dialog header="Realizar mismo pedido" baseZIndex={1001} visible={displayConfirmation} onHide={() => setDisplayConfirmation(false)} style={{ width: '350px' }} modal footer={confirmationDialogFooter}>
                <div className="confirmation-content confirmapedido">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    <span>Está seguro que quiere realizar el mismo pedido?</span>
                </div>
            </Dialog>
        </div>
    )
}
